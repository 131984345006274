<template>
  <v-card>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="data"
        sort-by="created_at"
        :sort-desc="true"
        :search="search"
        class="elevation-1"
        :loading="loading"
        loading-text="Загрузка..."
        :items-per-page="20"
        hide-default-footer
        :page.sync="page"
        @page-count="pageCount = $event"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>{{title}}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <div class="flex-grow-1"></div>
            <v-text-field
             v-model="search"
             append-icon="mdi-magnify"
             label="Поиск"
             single-line
             hide-details
             ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item.status.name="{ item }">
          <v-chip :color="getStatusColor(item.status)" dark>{{ item.status.name }}</v-chip>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">Обновить</v-btn>
        </template>
      </v-data-table>
      <div class="text-center pt-2" v-show="pageCount > 1">
        <v-pagination 
          v-model="page" 
          :length="pageCount"
          :total-visible="12"
        >
        </v-pagination>
      </div>
    </v-card-text>

    <ErrorDialog
      v-model="dialogErr"
      title="Ошибка"
      text="В процессе обработки данных произошла ошибка. Проверьте работу сети и повторите попытку позже."
      >
    </ErrorDialog>

  </v-card>
</template>

<script>
  import ErrorDialog from '@/components/ErrorDialog'

  export default {
    components: {
      ErrorDialog
    },
    data: () => ({
      title: 'Склад',
      modname: 'parts',
      search: '',
      page: 1,
      pageCount: 0,
      loading: true,
      dialogErr: false,
      headers: [
        { text: 'Артикул', align: 'center', sortable: true, value: 'slug', width: '20%'},
        { text: 'Наименование', align: 'left', sortable: true, value: 'name'},
        { text: 'Единица', align: 'left', sortable: true, value: 'unit'},
      ],
      data: [],
    }),

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.$axios.get(this.modname)
          .then((r) => {
            this.data = r.data;
          })
          .catch(error => {
            this.crudError(error)
          })
          .finally(() => {
            this.loading = false
          })
      },

      crudError (error) {
        // eslint-disable-next-line
        console.error('Statement update error', error, this.data)
        this.openError()
      },

      openError () {
        this.dialogErr = !this.dialogErr
      },

    },
  }
</script>
